import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";

const theme = createTheme({
  typography: {
    
    h3: {
      fontWeight: 800,
    },
    h4: {
      fontWeight: 800,
    },
    h5: {
      fontWeight: 800,
    },
    h6: {
      fontWeight: 800,
    },
    subtitle1: {
      fontWeight: 800,
      color: "grey"
    },

    button: {
      textTransform: "none",
      fontWeight: 800
    },
    
    fontFamily: [
      'Be Vietnam Pro',
      'sans-serif'
    ].join(','),
  },


    
  });

export default theme;