import React from "react"

const defaultState = {
    isSignedIn: false,
    uname: "None"
}

const UserContext = React.createContext(defaultState)

class UserContextProvider extends React.Component {
    state = {
        isSignedIn: false,
        uname: "None",
        updateUserContext: () => {},
        signedIn: () => {}
    }

    updateUserContext = (uname) => {
      this.setState({uname: uname})
    }

    signedIn = () => {
      this.setState({isSignedIn: true})
    }

    render() {
        const { children } = this.props
        const { isSignedIn } = this.state
        const { uname } = this.state
        return (
          <UserContext.Provider
            value={{
                isSignedIn,
                uname,
                updateUserContext: this.updateUserContext,
                signedIn: this.signedIn
            }}
          >
            {children}
          </UserContext.Provider>
        )
      }
}

export default UserContext

export {UserContextProvider}