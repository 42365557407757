/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles.css"

import React from "react"

import {UserContextProvider} from "./src/context/UserContext"

export const wrapRootElement = ({ element }) => (
    <UserContextProvider>{element}</UserContextProvider>
  )
